import { dev } from "$app/environment";
import * as Sentry from "@sentry/svelte";

if (!dev) {
	Sentry.init({
		dsn: "https://2183aa3d26499890f923fc5c465c4c9f@o4507887179661312.ingest.us.sentry.io/4507887188049920",
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
	});
}
